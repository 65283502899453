import React from "react";
import * as cardStyles from "../../styles/card.module.css";

const StatusLabel = ({ validated }) => (
    <div>
        <div className={`${cardStyles.statusLabel} ${validated === "false" ? cardStyles.pending : cardStyles.validated}`}>
            { validated === "false" ? "Pending" : "Validated" }
        </div>
    </div>
);

export default StatusLabel;