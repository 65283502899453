import React, { useState, useEffect } from "react"
import * as pagingStyles from "./paginate.module.css"

const Paging = props => {

    const [state, setState] = useState({
        nextEnabled: props.nextEnabled,
        previousEnabled: props.previousEnabled
    })

    useEffect(() => {
        setState({
            nextEnabled: props.nextEnabled,
            previousEnabled: props.previousEnabled
        });
      }, [props]);


    const onNextClick = (e) => {
        if(state.nextEnabled) {
            props.onNextClick(e);
        }
    }

    const onPreviousClick = (e) => {
        if(state.previousEnabled) {
            props.onPreviousClick(e);
        }
    }

    const { previousEnabled, nextEnabled } = state;
    const previousClasses = previousEnabled ? pagingStyles.previous : pagingStyles.previous + ' ' + pagingStyles.disabled;
    const nextClasses = nextEnabled ? pagingStyles.next : pagingStyles.next + ' ' + pagingStyles.disabled;

    return (
        <>
        <ul className={pagingStyles.pagination}>
            <li className={previousClasses} onClick={onPreviousClick} onMouseDown={(e) => e.preventDefault()}>
                <a tabIndex="0" role="button" aria-disabled="true">previous</a>
            </li>
            <li className={nextClasses} onClick={onNextClick} onMouseDown={(e) => e.preventDefault()}>
                <a tabIndex="0" role="button" aria-disabled="false">next</a>
            </li>
        </ul>
        </>
    )
}

export default Paging