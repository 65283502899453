import React, { forwardRef, useImperativeHandle, useRef } from "react"
import "./styles.css"
import { useDetectOutsideClick } from "./useDetectOutsideClick";

export const DropdownMenu = forwardRef((props, ref) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    useImperativeHandle(ref, () => {
        return {
            setIsActive: setIsActive
        }
    });

    return (
        <div className="menu-container">
            <button className="button" onClick={onClick}>
                <svg className="button-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
            </button>
            <div ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
                {props.children}
            </div>
        </div>
    )
})

export default DropdownMenu
