import React, { useState, createContext, useContext, useEffect } from "react"
import { UserContext } from "./usercontextprovider"
import { API, graphqlOperation } from "aws-amplify"
import { LoadMaskContext } from "./loadmaskprovider"

export const UserTenantContext = createContext([[], () => {}])

const listUserTenants = /* GraphQL */ `
  query ListUserTenants(
    $userId: ID
    $filter: ModelUserTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTenants(userId: $userId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        tenantId
        tenantRole
        tenantObj {
          name
          description
          prettyName
          adminGroup
          allowedRoles
          resultMetricEmailTime
          resultMetricEmailList
          tenantAdminGroup
          questionnaireValidityTimeInSeconds
        }
      }
      nextToken
    }
  }
`

export const UserTenantProvider = props => {
  const [userContext, setUserContext] = useContext(UserContext)
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [userTenant, setUserTenant] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      let result = await API.graphql(
        graphqlOperation(listUserTenants, {
          userId: userContext.username
        })
      )
      setUserTenant(result.data.listUserTenants.items)
      setIsLoading(false)
    }
    if (userContext.username) {
      setIsLoading(true)
      fetchData()
    }
  }, [userContext.username])

  return (
    <UserTenantContext.Provider value={[userTenant, setUserTenant]}>
      {props.children}
    </UserTenantContext.Provider>
  )
}
