import React, { useContext, useEffect, useRef, useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import Select from 'react-select'
import { UserTenantContext } from "../provider/usertenantprovider"
import './user-list.css'
import DropdownMenu from "./dropdownmenu/dropdownmenu"
import * as buttonStyles from './button.module.css'
import ReactModal from 'react-modal';
import * as modalStyles from './modalstyles.module.css'
import * as inputStyles from './search-input.module.css'
import * as cardStyles from "../styles/card.module.css";
import * as badgeStyles from "../styles/badge.module.css";
import { LoadMaskContext } from "../provider/loadmaskprovider";

import Paging from "./paging"
import Card from "./cards/card"
import VerticalCard from "./cards/vertical-card"
import StatusLabel from "./cards/status-label"

const getTenantInviteByTenantIdAndValidated = /* GraphQL */ `
  query getTenantInviteByTenantIdAndValidated(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTenantInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantInviteByTenantIdAndValidated(
      tenantId: $tenantId
      validated: {eq: "false"}
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        firstName
        lastName
        username
        tenantRole
        validated
      }
      nextToken
    }
  }
`
const createTenantInvite = /* GraphQL */ `
  mutation CreateTenantInvite(
    $input: CreateTenantInviteInput!
  ) {
    createTenantInvite(input: $input) {
      id
    }
  }
`;

const updateTenantInvite = /* GraphQL */ `
  mutation updateTenantInvite($input: UpdateTenantInviteInput!){
    updateTenantInvite(input: $input,condition: { validated: { eq: "false" }}){
      id
    }
  }
`

const deleteTenantInvite = /* GraphQL */ `
  mutation deleteTenantInvite($input: DeleteTenantInviteInput!){
    deleteTenantInvite(input: $input,condition: { validated: { eq: "false" }}){
      id
    }
  }
`

const widthStyles = {
    container: base => ({
        ...base,
        width: '50%'
    })
};

const emptyModalState = {
    emailAddress: "",
    firstName: "",
    lastName: "",
    tenantRole: [],
    tenantProvidedUserId: ""
}

const TenantInviteComponent = props => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext)
    const [tenant, setTenant] = useState(null)
    const [tenantList, setTenantList] = useContext(UserTenantContext);
    const [searchState, setSearchState] = useState({ results: [], tokenStack: [] })
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalState, setModalState] = useState(emptyModalState)
    const [selectOptions, setSelectOptions] = useState([]);
    const [valid, setValid] = useState({
        emailAddress: false,
        firstName: false,
        lastName: false
    });

    const filterLimit = 18;

    useEffect(() => {
        getTenantInvites()
    }, [tenant, searchState.currentToken])

    const getTenantInvites = async () => {
        setIsLoading(true)
        let tenantInviteGql = null
        let nextToken = searchState.currentToken;
        if (tenant) {
            tenantInviteGql = await API.graphql(graphqlOperation(getTenantInviteByTenantIdAndValidated, { nextToken: nextToken, limit: filterLimit, tenantId: tenant.value }));
            console.log(tenantInviteGql)
        }
        if (tenantInviteGql) {
            const invites = tenantInviteGql.data.getTenantInviteByTenantIdAndValidated.items
            setSearchState({
                ...searchState,
                tokenStack: [...searchState.tokenStack, tenantInviteGql.data.getTenantInviteByTenantIdAndValidated.nextToken],
                results: tenantInviteGql.data.getTenantInviteByTenantIdAndValidated.items
            });
        }
        setIsLoading(false)
    }

    const onTenantChange = e => {
        setTenant(e)
        console.log(e)
    }

    const previousPage = () => {
        const currentToken = searchState.tokenStack[searchState.tokenStack.length - 3]
        setSearchState({
            ...searchState,
            tokenStack: searchState.tokenStack.splice(0, searchState.tokenStack.length - 2),
            currentToken: currentToken
        }
        )
        topRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const nextPage = () => {
        setSearchState({
            ...searchState,
            currentToken: searchState.tokenStack[searchState.tokenStack.length - 1]
        })
        topRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    let allowedRoleOptions = []
    if (tenant) {
        allowedRoleOptions = tenant.allowedRoles.map(role => {
            return {
                value: role,
                label: role
            }
        })
    }
    const tenantOptions = tenantList.filter(t => t.tenantRole.includes("Admin")).map((tenant) => {
        return {
            value: tenant.tenantObj.name,
            label: tenant.tenantObj.prettyName,
            allowedRoles: tenant.tenantObj.allowedRoles
        }
    })

    const getTitle = (user) => {
        if (user.firstName && user.lastName) {
            return `${user.firstName} ${user.lastName}`
        } else {
            return user.emailAddress
        }
    }

    const createProps = (tenantInvite) => {
        let props = [
            { name: 'ID', value: tenantInvite.tenantProvidedUserId },
            { name: 'Email', value: tenantInvite.emailAddress },
            { name: 'Username', value: tenantInvite.userId ? tenantInvite.userId : "N/A" },
            { name: 'Pending', value: tenantInvite.validated === "false" ? "Yes" : "No" },
            { name: 'Roles', value: `${tenantInvite.tenantRole}` }
        ]
        return props
    }
    const dropdownMenuRef = useRef(null);
    const topRef = useRef(null);

    const resendInvite = (invite) => async () => {
        dropdownMenuRef.current.setIsActive(false)
        console.log(invite)
        try {
            setIsLoading(true)
            let result = await API.graphql(
                graphqlOperation(updateTenantInvite, {
                    input: {
                        id: invite.id,
                        verificationCode: Math.floor(100000 + Math.random() * 900000),
                        verificationExpiration: new Date().toISOString()
                    }
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const deleteInvite = (invite) => async () => {
        dropdownMenuRef.current.setIsActive(false)
        console.log(invite)
        setIsLoading(true)
        let result = await API.graphql(
            graphqlOperation(deleteTenantInvite, {
                input: {
                    id: invite.id
                }
            })
        )
        setIsLoading(false)
        getTenantInvites()
    }

    ReactModal.setAppElement(`#___gatsby`);
    const openModal = async () => {
        setIsOpen(true)
        const newSelectOptions = tenant.allowedRoles.map(role => {
            const option = {
                value: role,
                label: role
            };
            return option;
        })
        setSelectOptions(newSelectOptions)
    }
    const closeModal = () => {
        setIsOpen(false)
    }
    const modalSave = async () => {
        let input = { ...modalState }
        const current = tenantList.find(obj => obj.tenantObj.name === tenant.value)
        input.tenantId = tenant.value
        input.tenantAdminGroup = current.tenantObj.tenantAdminGroup
        input.validated = "false"
        input.verificationCode = Math.floor(100000 + Math.random() * 900000)
        input.verificationExpiration = new Date().toISOString()
        input.tenantRole = Array.isArray(input.tenantRole) ? input.tenantRole.map(role => role.value) : []
        console.log(input)

        try {
            setIsLoading(true)
            await API.graphql(graphqlOperation(createTenantInvite, { input }))
        } finally {
            setIsLoading(false)
            setIsOpen(false)
            setModalState(emptyModalState)
            getTenantInvites()
        }
    }
    const saveBtnRef = useRef(null)
    const changeRoles = (newOptions, evt) => {
        setModalState({
            ...modalState,
            tenantRole: newOptions
        })
    }
    const changeTextInput = (evt) => {
        if (Object.keys(valid).includes(evt.target.name)) {
            let newValid = { ...valid }
            newValid[evt.target.name] = evt.target.validity.valid
            setValid(newValid)
        }

        let newModalState = {
            ...modalState,
            [evt.target.name]: evt.target.value
        }
        setModalState(newModalState)
    }
    const changeEmailInput = (evt) => {
        let newValid = { ...valid }
        newValid.emailAddress = evt.target.validity.valid
        setValid(newValid)
        console.log(saveBtnRef)
        let newModalState = {
            ...modalState,
            [evt.target.name]: evt.target.value
        }
        setModalState(newModalState)
    }

    const previousEnabled = searchState.tokenStack.length > 1;
    const nextEnabled = searchState.tokenStack[searchState.tokenStack.length - 1] != null;


    return (
        <>
            <h1>Tenant Invites</h1>
            <div ref={topRef} style={{ display: "flex", justifyContent: "space-between" }}>
                <Select isSearchable={false} styles={widthStyles} options={tenantOptions} onChange={onTenantChange} />
                <button disabled={!tenant} onClick={openModal} style={{ margin: "0 0 0 5px" }} className={buttonStyles.button}>New Invitation</button>
            </div>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Invite User"
                className={modalStyles.modal}
                overlayClassName={modalStyles.overlay}>
                <div className={modalStyles.modalContent}>
                    <div className={modalStyles.modalHeader}>
                        <h2>Invite User</h2>
                    </div>
                    <div className={modalStyles.modalBody}>
                        <label>
                            Roles
                  <Select className={modalStyles.modalInput} onChange={changeRoles} options={selectOptions} isMulti />
                        </label>
                        <label>
                            First Name
                  <input required onChange={changeTextInput} name="firstName" value={modalState.firstName} className={`${inputStyles.inputcss} ${modalStyles.modalInput}`} type="text" />
                        </label>
                        <label>
                            Last Name
                  <input required onChange={changeTextInput} name="lastName" value={modalState.lastName} className={`${inputStyles.inputcss} ${modalStyles.modalInput}`} type="text" />
                        </label>
                        <label>
                            Email Address
                  <input required type="email" onChange={changeEmailInput} name="emailAddress" value={modalState.emailAddress} className={`${inputStyles.inputcss} ${modalStyles.modalInput}`} />
                        </label>
                        <label>
                            Tenant Provided ID
                  <input onChange={changeTextInput} name="tenantProvidedUserId" value={modalState.tenantProvidedUserId} className={`${inputStyles.inputcss} ${modalStyles.modalInput}`} type="text" />
                        </label>
                    </div>
                    <div className={modalStyles.modalFooter}>
                        <div style={{ float: "right" }}>
                            <button className={buttonStyles.secondaryButton} onClick={closeModal}>Close</button>
                            <button disabled={Object.values(valid).some(val => val === false)} className={buttonStyles.button} onClick={modalSave}>Save</button>
                        </div>
                    </div>
                </div>
            </ReactModal>

            {tenant && (
                <>
                    <ul className={cardStyles.cardList}>
                        {searchState.results.map(tenantInvite => (
                            <li key={tenantInvite.id}>
                                <Card>
                                    <DropdownMenu ref={dropdownMenuRef}>
                                        <div>
                                            <a onClick={resendInvite(tenantInvite)} href="#">Resend</a>
                                        </div>
                                        <div>
                                            <a onClick={deleteInvite(tenantInvite)} href="#">Delete</a>
                                        </div>
                                    </DropdownMenu>
                                    <VerticalCard>
                                        <strong>{getTitle(tenantInvite)}</strong>
                                        <p>{tenantInvite.emailAddress}</p>
                                        <div style={{display: "inlineFlex", flexWrap: "wrap", justifyContent: "center"}} className={badgeStyles.badgeList}>
                                            {tenantInvite.tenantRole.map(role => (
                                                <div style={{marginBottom: "5px"}} className={`${badgeStyles.badge} ${badgeStyles.badgeRounded}`}>{role}</div>
                                            ))}
                                        </div>
                                    </VerticalCard>
                                    <StatusLabel validated={tenantInvite.validated} />
                                </Card>
                            </li>
                        ))}
                    </ul>
                    <Paging onNextClick={nextPage} onPreviousClick={previousPage} previousEnabled={previousEnabled} nextEnabled={nextEnabled} />
                </>
            )}
        </>
    )
}

export default TenantInviteComponent
